import React, { useState, useEffect, useLayoutEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PracticeAreas = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [practiceAreasHeadingsState, setPracticeAreasHeadingsState] = useState(
    []
  );

  const data = useStaticQuery(graphql`
    query {
      practiceAreasData: markdownRemark(
        frontmatter: { type: { eq: "business-information" } }
      ) {
        frontmatter {
          practiceAreas {
            practiceAreasList {
              overview
              iconPosition
              iconName
              heading
              description {
                subHeading
                subDescription
              }
            }
            secondary
            generalOverview
          }
        }
      }
    }
  `);

  const {
    practiceAreasData: {
      frontmatter: {
        practiceAreas: { practiceAreasList },
      },
    },
  } = data;

  const practiceAreasMap = new Map();
  practiceAreasList.forEach((item) =>
    practiceAreasMap.set(item.heading, item.description)
  );

  const practiceAreasHeadings = Array.from(practiceAreasMap.keys());
  const [currentPracticeAreaHeading, setCurrentPracticeAreaHeading] = useState(
    practiceAreasHeadings[0]
  );

  useEffect(() => {
    setPracticeAreasHeadingsState(practiceAreasHeadings);
  }, []);

  useEffect(() => {
    setPracticeAreasHeadingsState((prevHeadings) => [
      currentPracticeAreaHeading,
      ...prevHeadings.filter(
        (heading) => heading !== currentPracticeAreaHeading
      ),
    ]);
  }, [currentPracticeAreaHeading]);

  const currentDescriptions =
    practiceAreasMap.get(currentPracticeAreaHeading) || [];
    // practiceAreasMap.get(currentPracticeAreaHeading); the fallback empty array above may be redundant as this also worked

  return (
    <Layout>
      <section className="container">
        <div className="row">
          <nav
            className="practice-areas-nav position-relative text-center mx-auto"
            style={{
              zIndex: 1000,
              minHeight: "6.5em",
              maxWidth: "50vw",
              top: "0.3em",
            }}
          >
            <button
              className="card-header py-1 h5 fw-bold text-uppercase border-0"
              style={{
                color: "goldenrod",
                backgroundColor: "rgb(18, 21, 24)",
                display: "block",
                textAlign: "center",
                margin: "0 auto",
                width: "100%",
                fontSize: "1.15em",
              }}
              onClick={() => setMenuOpen((mo) => !mo)}
            >
              Section(s)
              {menuOpen ? (
                <FontAwesomeIcon
                  icon="caret-up"
                  width="1em"
                  color="goldenrod"
                  background="rgb(18, 21, 24)"
                  className="ms-3"
                />
              ) : (
                <FontAwesomeIcon
                  icon="caret-down"
                  width="1em"
                  color="goldenrod"
                  background="rgb(18, 21, 24)"
                  className="ms-3"
                />
              )}
            </button>
            <menu
              className="list-group list-group-flush position-absolute w-100 list-unstyled m-0 start-0 end-0"
              style={{
                top: "1.8em",
                paddingRight: "0.75em",
                paddingLeft: "0.75em",
              }}
            >
              {practiceAreasHeadingsState.map((heading, index) => (
                <>
                  {index >= 1 ? (
                    menuOpen && (
                      <li key={heading}>
                        <button
                          style={{
                            backgroundColor: "rgb(18, 21, 24)",
                            color: "goldenrod",
                          }}
                          className="border-0 w-100"
                          onClick={() => {
                            setCurrentPracticeAreaHeading(heading);
                            setMenuOpen(false);
                          }}
                        >
                          {heading}
                        </button>
                      </li>
                    )
                  ) : (
                    <li key={heading}>
                      <button
                        style={{
                          backgroundColor: "rgb(18, 21, 24)",
                          color: "goldenrod",
                          fontWeight: "bold",
                        }}
                        className="border-0 w-100"
                        onClick={() => {
                          setCurrentPracticeAreaHeading(heading);
                          setMenuOpen(false);
                        }}
                      >
                        {heading}
                      </button>
                    </li>
                  )}
                </>
              ))}
            </menu>
          </nav>
          <div className="practice-areas-view">
            {currentDescriptions.map((item, idx) => (
              <>
                <h2>{item.subHeading}</h2>
                <p>{item.subDescription}</p>
                <br />
              </>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PracticeAreas;
